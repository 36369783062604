import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const initialState = {
  isLoading: false,
  error: null,
  ingredients: [],
  ingredient: null,
};

const slice = createSlice({
  name: "ingredient",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGORIES
    getIngredientsSuccess(state, action) {
      state.isLoading = false;
      state.ingredients = action.payload;
    },

    // GET CATEGORY
    getIngredientSuccess(state, action) {
      state.isLoading = false;
      state.ingredient = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function getIngredient(ingredientId, getToken) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/ingredient/${ingredientId}`,
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      dispatch(slice.actions.getIngredientSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getIngredients(levelId, venueId, getToken) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/ingredient`, {
        headers: {
          Authorization: `Bearer ${getToken}`,
        },
        params: {
          venueId: venueId,
          levelId: levelId,
          limit: 10000000,
          isDeleted: false,
        },
      });

      dispatch(slice.actions.getIngredientsSuccess(response.data.results));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
