import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import MainLayout from "../layouts/main";
import SimpleLayout from "../layouts/simple";
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config-global";
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralReportPage,
  GeneralBookingPage,
  LedgerPage,
  GeneralEcommercePage,
  GeneralAnalyticsPage,
  // Dashboard: Bar
  BarListPage,
  BarCreatePage,
  BarLevelCreatePage,
  BarEditPage,
  BarAccountPage,
  // Dashboard: Booking
  BookingListPage,
  // Dashboard: Table
  TableAccountPage,
  TableListPage,
  TableCreatePage,
  TableEditPage,
  // Dashboard: Area
  AreaCreatePage,
  AreaEditPage,
  // Dashboard: Desigination
  DesiginationCreatePage,
  DesiginationEditPage,
  // Dashboard: Reservation
  ReservationListPage,
  ReservationCalendarPage,
  ReservationCreatePage,
  ReservationEditPage,
  // Dashboard: Order
  OrderListPage,
  OrderCreatePage,
  OrderEditPage,
  OrderAccountPage,
  OrderDetailsPage,
  // Dashboard: Complaint
  ComplaintListPage,
  ComplaintCreatePage,
  ComplaintEditPage,
  ComplaintAccountPage,
  ComplaintDetailsPage,
  // Dashboard: Notification
  NotificationListPage,
  // NotificationCreatePage,
  // NotificationEditPage,
  // NotificationAccountPage,
  // NotificationDetailsPage,
  // Dashboard: Campaign
  CampaignListPage,
  CampaignCreatePage,
  CampaignEditPage,
  CampaignAccountPage,
  // Dashboard: Settings
  SettingsCreatePage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Dashboard: Menu
  // MenuListPage,
  // MenuEditPage,
  // MenuCardsPage,
  // MenuCreatePage,
  // MenuProfilePage,
  MenuDisplayPage,
  InventoryDisplayPage,

  // Dashboard: Category
  CategoryCreatePage,
  CategoryEditPage,
  // Dashboard: Item
  ItemCreatePage,
  ItemEditPage,
  // Dashboard: Ingredient
  IngredientCreatePage,
  IngredientEditPage,
  // Dashboard: Inventory
  AddStockPage,
  AddAdjustmentPage,
  AdjustmentEditPage,
  AllStockEditPage,
  // Dashboard: Choice Group
  ChoiceGroupCreatePage,
  ChoiceGroupEditPage,
  // Dashboard: Food
  FoodCreatePage,
  // Dashboard: Drink
  DrinkCreatePage,
  // Dashboard: Customer
  CustomerListPage,
  CustomerEditPage,
  CustomerCreatePage,
  CustomerAccountPage,
  // Dashboard: POS
  POSPage,
  POSOrderListPage,
  TablesPage,
  // Dashboard: ORDERS
  OrdersPage,
  // Dashboard: KITCHEN
  KitchenPage,
  RecipePage,
  RecipeEditPage,
  // Dashboard: Ecommerce
  EcommerceShopPage,
  EcommerceCheckoutPage,
  EcommerceProductListPage,
  EcommerceProductEditPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  // Dashboard: FileManager
  FileManagerPage,
  // Dashboard: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  MerchantPage,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  //
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  //
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  //
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  DemoMarkdownPage,
  QrCodeScanPage,
} from "./elements";
import PrivateRoute from "./PrivateRoute";
import { useAuthContext } from "src/auth/useAuthContext";
// import AllStockEditPage from "src/pages/dashboard/IMS/stocks/AllStockEditPage";

export default function Router() {
  const { user, logout } = useAuthContext();
  return useRoutes([
    // Auth
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        // { path: 'login-unprotected', element: <LoginPage /> },
        {
          element: <CompactLayout />,
          children: [{ path: "login-unprotected", element: <LoginPage /> }],
        },
        { path: "register-unprotected", element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: "reset-password", element: <ResetPasswordPage /> },
            { path: "new-password", element: <NewPasswordPage /> },
            { path: "verify", element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "app",
          element: (
            <PrivateRoute
              component={GeneralAppPage}
              permission="canViewDashboard"
            />
          ),
        },
        { path: "ecommerce", element: <GeneralEcommercePage /> },
        { path: "analytics", element: <GeneralAnalyticsPage /> },
        { path: "banking", element: <GeneralBankingPage /> },
        { path: "QrCode", element: <QrCodeScanPage /> },
        {
          path: "report",
          element: (
            <PrivateRoute
              component={GeneralReportPage}
              permission="canAccessReports"
            />
          ),
        },
        {
          path: "ledger",
          element: (
            <PrivateRoute component={LedgerPage} permission="canViewLedger" />
          ),
        },
        // { path: 'ledger', element: <LedgerPage /> },
        { path: "booking", element: <GeneralBookingPage /> },
        { path: "file", element: <GeneralFilePage /> },
        {
          path: "e-commerce",
          children: [
            {
              element: <Navigate to="/dashboard/e-commerce/shop" replace />,
              index: true,
            },
            { path: "shop", element: <EcommerceShopPage /> },
            { path: "product/:name", element: <EcommerceProductDetailsPage /> },
            { path: "list", element: <EcommerceProductListPage /> },
            { path: "product/new", element: <EcommerceProductCreatePage /> },
            {
              path: "product/:name/edit",
              element: <EcommerceProductEditPage />,
            },
            { path: "checkout", element: <EcommerceCheckoutPage /> },
          ],
        },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <UserProfilePage /> },
            { path: "cards", element: <UserCardsPage /> },
            { path: "list", element: <UserListPage /> },
            {
              path: "new",
              element: (
                <PrivateRoute
                  component={UserCreatePage}
                  permission="canAddStaffAndRoles"
                />
              ),
            },
            {
              path: ":id/edit",
              element: <UserEditPage />,
            },
            // {
            //   path: ":id/edit",
            //   element: (
            //     <PrivateRoute
            //       component={UserEditPage}
            //       permission="canEditStaffDetails"
            //     />
            //   ),
            // },

            {
              path: ":id/replicate",
              element: <UserEditPage />,
            },
            // {
            //   path: ":id/replicate",
            //   element: (
            //     <PrivateRoute
            //       component={UserEditPage}
            //       permission="canEditStaffDetails"
            //     />
            //   ),
            // },
            {
              path: "account",
              element: (
                <PrivateRoute
                  component={UserAccountPage}
                  permission="canViewStaff"
                />
              ),
            },
          ],
        },
        {
          path: "menu",
          children: [
            {
              element: <Navigate to="/dashboard/menu/profile" replace />,
              index: true,
            },
            { path: "display", element: <MenuDisplayPage /> },
          ],
        },

        {
          path: "inventory",
          children: [
            {
              element: <Navigate to="/dashboard/menu/profile" replace />,
              index: true,
            },
            {
              path: "new",
              element: (
                <PrivateRoute
                  component={AddStockPage}
                  // superAdminRoute={true}
                />
              ),
            },
            {
              path: "adjustment/new",
              element: (
                <PrivateRoute
                  component={AddAdjustmentPage}
                  // superAdminRoute={true}
                />
              ),
            },
            // { path: ':id/edit', element: <PrivateRoute component={ItemEditPage} superAdminRoute={true} /> },
            { path: "display", element: <InventoryDisplayPage /> },

            {
              path: ":id/edit",
              element: <AllStockEditPage />,
            },
            {
              path: "adjustment/:id/edit",
              element: <AdjustmentEditPage />,
            },
          ],
        },

        {
          path: "category",
          children: [
            {
              element: <Navigate to="/dashboard/category/profile" replace />,
              index: true,
            },
            {
              path: "new",
              element: (
                <PrivateRoute
                  component={CategoryCreatePage}
                  permission="canAddItemCategoryAndChoicegroup"
                />
              ),
            },
            {
              path: ":id/edit",
              element: (
                <PrivateRoute
                  component={CategoryEditPage}
                  permission="canEditItemCategoryAndChoicegroup"
                />
              ),
            },
            ,
            {
              path: ":id/replicate",
              element: (
                <PrivateRoute
                  component={CategoryEditPage}
                  permission="canAddItemCategoryAndChoicegroup"
                />
              ),
            },
          ],
        },
        {
          path: "item",
          children: [
            {
              element: <Navigate to="/dashboard/item/profile" replace />,
              index: true,
            },
            {
              path: "new",
              element: (
                <PrivateRoute
                  component={ItemCreatePage}
                  permission="canAddItemCategoryAndChoicegroup"
                  // superAdminRoute={true}
                />
              ),
            },
            {
              path: ":id/edit",
              element: (
                <PrivateRoute
                  component={ItemEditPage}
                  permission="canEditItemCategoryAndChoicegroup"
                />
              ),
            },
            {
              path: ":id/replicate",
              element: (
                <PrivateRoute
                  component={ItemEditPage}
                  permission="canAddItemCategoryAndChoicegroup"
                />
              ),
            },
          ],
        },
        {
          path: "ingredient",
          children: [
            {
              element: <Navigate to="/dashboard/ingredient/profile" replace />,
              index: true,
            },
            {
              path: "new",
              element: (
                <PrivateRoute
                  component={IngredientCreatePage}
                  // superAdminRoute={true}
                />
              ),
            },
            {
              path: ":id/edit",
              element: (
                <PrivateRoute
                  component={IngredientEditPage}
                  // superAdminRoute={true}
                />
              ),
            },
          ],
        },
        {
          path: "recipe",
          children: [
            {
              element: <Navigate to="/dashboard/recipe/profile" replace />,
              index: true,
            },
            {
              path: "new",
              element: (
                <PrivateRoute
                  component={RecipePage}
                  // superAdminRoute={true}
                />
              ),
            },
            {
              path: ":id/edit",
              element: (
                <PrivateRoute
                  component={RecipeEditPage}
                  // superAdminRoute={true}
                />
              ),
            },
            {
              path: ":id/replicate",
              element: (
                <PrivateRoute
                  component={RecipeEditPage}
                  // superAdminRoute={true}
                />
              ),
            },
            // {
            //   path: ":id/edit",
            //   element: (
            //     <PrivateRoute
            //       component={IngredientEditPage}
            //       superAdminRoute={true}
            //     />
            //   ),
            // },
          ],
        },

        {
          path: "choice",
          children: [
            {
              element: <Navigate to="/dashboard/choice/profile" replace />,
              index: true,
            },
            {
              path: "new",
              element: (
                <PrivateRoute
                  component={ChoiceGroupCreatePage}
                  permission="canAddItemCategoryAndChoicegroup"
                />
              ),
            },
            {
              path: ":id/edit",
              element: (
                <PrivateRoute
                  component={ChoiceGroupEditPage}
                  permission="canEditItemCategoryAndChoicegroup"
                />
              ),
            },
            {
              path: ":id/replicate",
              element: (
                <PrivateRoute
                  component={ChoiceGroupEditPage}
                  permission="canAddItemCategoryAndChoicegroup"
                />
              ),
            },
          ],
        },
        {
          path: "food",
          children: [
            {
              element: <Navigate to="/dashboard/food/profile" replace />,
              index: true,
            },
            { path: "new", element: <FoodCreatePage /> },
          ],
        },
        {
          path: "drink",
          children: [
            {
              element: <Navigate to="/dashboard/drink/profile" replace />,
              index: true,
            },
            { path: "new", element: <DrinkCreatePage /> },
          ],
        },
        {
          path: "customer",
          children: [
            {
              element: <Navigate to="/dashboard/customer/profile" replace />,
              index: true,
            },
            {
              path: "list",
              element: (
                <PrivateRoute
                  component={CustomerListPage}
                  permission="canViewVenueCustomers"
                />
              ),
            },
            // { path: 'list', element: <CustomerListPage /> },
            { path: "new", element: <CustomerCreatePage /> },
            { path: ":id/edit", element: <CustomerEditPage /> },
            { path: "account", element: <CustomerAccountPage /> },
          ],
        },
        {
          children: [
            {
              path: "pos",
              element: (
                <PrivateRoute component={POSPage} permission="canViewPos" />
              ),
              // index: true,
            },
          ],
        },
        {
          children: [
            {
              path: "orders",
              element: (
                <PrivateRoute
                  component={OrdersPage}
                  permission="canSeeOrderProcessingScreen"
                />
              ),
              // index: true,
            },
          ],
        },
        {
          children: [
            {
              path: "tables",
              element: (
                <PrivateRoute
                  component={TablesPage}
                  permission="canEditOrderOnPOS"
                />
              ),
              index: true,
            },
          ],
        },

        {
          children: [
            {
              path: "kitchen",
              element: (
                <PrivateRoute
                  component={KitchenPage}
                  permission="canSeeKitchenScreen"
                />
              ),
              index: true,
            },
          ],
        },

        {
          path: "bar",
          children: [
            {
              element: <Navigate to="/dashboard/bar/profile" replace />,
              index: true,
            },
            { path: "list", element: <BarListPage /> },
            { path: "new", element: <BarCreatePage /> },
            { path: "level", element: <BarLevelCreatePage /> },
            { path: ":name/edit", element: <BarEditPage /> },
            { path: "account", element: <BarAccountPage /> },
          ],
        },
        {
          path: "invoice",
          children: [
            {
              element: <Navigate to="/dashboard/invoice/list" replace />,
              index: true,
            },
            { path: "list", element: <InvoiceListPage /> },
            { path: ":id", element: <InvoiceDetailsPage /> },
            { path: ":id/edit", element: <InvoiceEditPage /> },
            { path: "new", element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: "order",
          children: [
            {
              element: <Navigate to="/dashboard/order/profile" replace />,
              index: true,
            },
            { path: "list", element: <OrderListPage /> },
            { path: "new", element: <OrderCreatePage /> },
            { path: ":id/detail", element: <OrderDetailsPage /> },
            { path: ":name/edit", element: <OrderEditPage /> },
            { path: "account", element: <OrderAccountPage /> },
          ],
        },
        {
          path: "reservation",
          children: [
            {
              element: <Navigate to="/dashboard/reservation/profile" replace />,
              index: true,
            },
            { path: "list", element: <ReservationListPage /> },
            {
              path: "calendar",
              element: (
                <PrivateRoute
                  component={ReservationCalendarPage}
                  permissions="canSeeReservationCalendar"
                />
              ),
            },
            // { path: "calendar", element: <ReservationCalendarPage /> },
            { path: "new", element: <ReservationCreatePage /> },
            // { path: ':id/detail', element: <ReservationDetailsPage /> },
            { path: ":name/edit", element: <ReservationEditPage /> },
            // { path: 'account', element: <ReservationAccountPage /> },
          ],
        },
        {
          path: "table",
          children: [
            {
              element: <Navigate to="/dashboard/table/profile" replace />,
              index: true,
            },
            { path: "list", element: <TableListPage /> },
            { path: "new", element: <TableCreatePage /> },
            // { path: ':id/detail', element: <TableDetailsPage /> },
            { path: ":id/edit", element: <TableEditPage /> },
            { path: "account", element: <TableAccountPage /> },
          ],
        },
        {
          path: "area",
          children: [
            {
              element: <Navigate to="/dashboard/table/profile" replace />,
              index: true,
            },
            { path: "new", element: <AreaCreatePage /> },
            { path: ":id/edit", element: <AreaEditPage /> },
          ],
        },
        {
          path: "desigination",
          children: [
            {
              element: <Navigate to="/dashboard/user/account" replace />,
              index: true,
            },
            {
              path: "new",
              element: (
                <PrivateRoute
                  component={DesiginationCreatePage}
                  permission="canAddStaffAndRoles"
                />
              ),
            },
            {
              path: ":id/edit",
              element: (
                <PrivateRoute
                  component={DesiginationEditPage}
                  permission="canEditStaffDetails"
                />
              ),
            },
          ],
        },
        {
          path: "complaint",
          children: [
            {
              element: <Navigate to="/dashboard/complaint/profile" replace />,
              index: true,
            },
            { path: "list", element: <ComplaintListPage /> },
            { path: "new", element: <ComplaintCreatePage /> },
            { path: ":id/detail", element: <ComplaintDetailsPage /> },
            { path: ":id/edit", element: <ComplaintEditPage /> },
            { path: "account", element: <ComplaintAccountPage /> },
          ],
        },
        {
          path: "notification",
          children: [
            {
              element: (
                <Navigate to="/dashboard/notification/profile" replace />
              ),
              index: true,
            },
            { path: "list", element: <NotificationListPage /> },
            // { path: 'new', element: <NotificationCreatePage /> },
            // { path: ':id/detail', element: <NotificationDetailsPage /> },
            // { path: ':name/edit', element: <NotificationEditPage /> },
            // { path: 'account', element: <NotificationAccountPage /> },
          ],
        },
        {
          path: "campaign",
          children: [
            {
              element: <Navigate to="/dashboard/campaign/profile" replace />,
              index: true,
            },
            { path: "list", element: <CampaignListPage /> },
            { path: "new", element: <CampaignCreatePage /> },
            { path: ":name/edit", element: <CampaignEditPage /> },
            { path: "account", element: <CampaignAccountPage /> },
          ],
        },
        {
          path: "settings",
          children: [
            {
              element: <Navigate to="/dashboard/settings/new" replace />,
              index: true,
            },
            { path: "new", element: <SettingsCreatePage /> },
          ],
        },
        {
          path: "blog",
          children: [
            {
              element: <Navigate to="/dashboard/blog/posts" replace />,
              index: true,
            },
            { path: "posts", element: <BlogPostsPage /> },
            { path: "post/:title", element: <BlogPostPage /> },
            { path: "new", element: <BlogNewPostPage /> },
          ],
        },
        { path: "files-manager", element: <FileManagerPage /> },
        {
          path: "mail",
          children: [
            {
              element: <Navigate to="/dashboard/mail/all" replace />,
              index: true,
            },
            { path: "label/:customLabel", element: <MailPage /> },
            { path: "label/:customLabel/:mailId", element: <MailPage /> },
            { path: ":systemLabel", element: <MailPage /> },
            { path: ":systemLabel/:mailId", element: <MailPage /> },
          ],
        },
        {
          path: "chat",
          children: [
            { element: <ChatPage />, index: true },
            { path: "new", element: <ChatPage /> },
            { path: ":conversationKey", element: <ChatPage /> },
          ],
        },
        { path: "calendar", element: <CalendarPage /> },
        { path: "kanban", element: <KanbanPage /> },
        { path: "permission-denied", element: <PermissionDeniedPage /> },
        { path: "blank", element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        {
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
          index: true,
        },
        { path: "about-us", element: <AboutPage /> },
        { path: "contact-us", element: <Contact /> },
        { path: "faqs", element: <FaqsPage /> },
        { path: "pricing", element: <PricingPage /> },
        { path: "merchant", element: <MerchantPage /> },
        // Demo Components
        {
          path: "components",
          children: [
            { element: <ComponentsOverviewPage />, index: true },
            {
              path: "foundation",
              children: [
                {
                  element: (
                    <Navigate to="/components/foundation/colors" replace />
                  ),
                  index: true,
                },
                { path: "colors", element: <FoundationColorsPage /> },
                { path: "typography", element: <FoundationTypographyPage /> },
                { path: "shadows", element: <FoundationShadowsPage /> },
                { path: "grid", element: <FoundationGridPage /> },
                { path: "icons", element: <FoundationIconsPage /> },
              ],
            },
            {
              path: "mui",
              children: [
                {
                  element: <Navigate to="/components/mui/accordion" replace />,
                  index: true,
                },
                { path: "accordion", element: <MUIAccordionPage /> },
                { path: "alert", element: <MUIAlertPage /> },
                { path: "autocomplete", element: <MUIAutocompletePage /> },
                { path: "avatar", element: <MUIAvatarPage /> },
                { path: "badge", element: <MUIBadgePage /> },
                { path: "breadcrumbs", element: <MUIBreadcrumbsPage /> },
                { path: "buttons", element: <MUIButtonsPage /> },
                { path: "checkbox", element: <MUICheckboxPage /> },
                { path: "chip", element: <MUIChipPage /> },
                { path: "data-grid", element: <MUIDataGridPage /> },
                { path: "dialog", element: <MUIDialogPage /> },
                { path: "list", element: <MUIListPage /> },
                { path: "menu", element: <MUIMenuPage /> },
                { path: "pagination", element: <MUIPaginationPage /> },
                { path: "pickers", element: <MUIPickersPage /> },
                { path: "popover", element: <MUIPopoverPage /> },
                { path: "progress", element: <MUIProgressPage /> },
                { path: "radio-button", element: <MUIRadioButtonsPage /> },
                { path: "rating", element: <MUIRatingPage /> },
                { path: "slider", element: <MUISliderPage /> },
                { path: "stepper", element: <MUIStepperPage /> },
                { path: "switch", element: <MUISwitchPage /> },
                { path: "table", element: <MUITablePage /> },
                { path: "tabs", element: <MUITabsPage /> },
                { path: "textfield", element: <MUITextFieldPage /> },
                { path: "timeline", element: <MUITimelinePage /> },
                { path: "tooltip", element: <MUITooltipPage /> },
                { path: "transfer-list", element: <MUITransferListPage /> },
                { path: "tree-view", element: <MUITreesViewPage /> },
              ],
            },
            {
              path: "extra",
              children: [
                {
                  element: <Navigate to="/components/extra/animate" replace />,
                  index: true,
                },
                { path: "animate", element: <DemoAnimatePage /> },
                { path: "carousel", element: <DemoCarouselsPage /> },
                { path: "chart", element: <DemoChartsPage /> },
                {
                  path: "copy-to-clipboard",
                  element: <DemoCopyToClipboardPage />,
                },
                { path: "editor", element: <DemoEditorPage /> },
                {
                  path: "form-validation",
                  element: <DemoFormValidationPage />,
                },
                { path: "image", element: <DemoImagePage /> },
                { path: "label", element: <DemoLabelPage /> },
                { path: "lightbox", element: <DemoLightboxPage /> },
                { path: "map", element: <DemoMapPage /> },
                { path: "mega-menu", element: <DemoMegaMenuPage /> },
                { path: "multi-language", element: <DemoMultiLanguagePage /> },
                { path: "navigation-bar", element: <DemoNavigationBarPage /> },
                {
                  path: "organization-chart",
                  element: <DemoOrganizationalChartPage />,
                },
                { path: "scroll", element: <DemoScrollbarPage /> },
                { path: "snackbar", element: <DemoSnackbarPage /> },
                { path: "text-max-line", element: <DemoTextMaxLinePage /> },
                { path: "upload", element: <DemoUploadPage /> },
                { path: "markdown", element: <DemoMarkdownPage /> },
              ],
            },
          ],
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [{ path: "payment", element: <PaymentPage /> }],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoonPage /> },
        { path: "maintenance", element: <MaintenancePage /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
