import PropTypes from "prop-types";
import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
// utils
import axios from "../utils/axios";
import localStorageAvailable from "../utils/localStorageAvailable";
import { useNavigate } from "react-router-dom";
//
import { isValidToken, setSession, setRefresh, setLevel } from "./utils";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  paymentMethods:null
};

const reducer = (state, action) => {

  if (action.type === "INITIAL") {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      paymentMethods:action.payload.paymentMethods?.results,
    };
  }
  if (action.type === "LOGIN") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      paymentMethods:action.payload.paymentMethods.results,
    };
  }
  if (action.type === "REGISTER") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      paymentMethods:action.payload.paymentMethods.results,
    };
  }
  if (action.type === "RESET") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      paymentMethods:action.payload.paymentMethods.results,
    };
  }
  if (action.type === "LOGOUT") {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      paymentMethods:null,

    };
  }
  if (action.type === "UPDATE_USER") {
    return {
      ...state,
      user: action.payload.user,      
    };
  }
  if(action.type=== "UPDATE_PAYMENT_METHOD"){
    return {
      ...state,
      paymentMethods: action.payload.paymentMethods,
    };
  }
  if(action.type=== "DELETE_PAYMENT_METHOD"){
    return {
      ...state,
      paymentMethods: action.payload.paymentMethods,
    };
  }



  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const refreshToken = storageAvailable
        ? localStorage.getItem("refreshToken")
        : "";
      const accessToken = storageAvailable
        ? localStorage.getItem("accessToken")
        : "";

      if (refreshToken && isValidToken(accessToken)) {
        setSession(accessToken);
        setRefresh(refreshToken);

        // const response = await axios.get('/api/account/my-account');
        const response = await axios.post(`${backendUrl}/authv/get-profile`, {
          refreshToken,
        });

        const { user,paymentMethods } = response.data;

        if (user?.venue?.levels?.length) {
          if (!localStorage.getItem("levelSelected")) {
            setLevel(user?.venue?.levels[0]);
          }
        }

        user.displayName = `${user?.firstName} ${user?.lastName} `;
        if(user?.venue?.status === "inActive"){
          dispatch({
            type: "INITIAL",
            payload: {
              isAuthenticated: false,
              user: null,
              paymentMethods:null
            },
          });
        }else{
          dispatch({
            type: "INITIAL",
            payload: {
              isAuthenticated: true,
              user,
              paymentMethods
            },
          });
        }
      } else {
        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: false,
            user: null,
            paymentMethods:null
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "INITIAL",
        payload: {
          isAuthenticated: false,
          user: null,
          paymentMethods:null
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (userName, password, ipAddress) => {
    // const response = await axios.post('https://api-dev-minimal-v4.vercel.app/api/account/login', {
    const response = await axios.post(`${backendUrl}/authv/login`, {
      userName,
      password,
      ipAddress,
    });
    const { tokens, user , paymentMethods } = response.data;

    user.displayName = `${user?.firstName} ${user?.lastName}`;

    setSession(tokens?.access?.token);
    setRefresh(tokens?.refresh?.token);
    dispatch({
      type: "LOGIN",
      payload: {
        user,
        paymentMethods
      },
    });
    if (user.permissions?.canViewDashboard) {
      navigate("/dashboard/app");
    } else if (user.permissions?.canViewPos) {
      navigate("/dashboard/pos");
    } else if (user.permissions?.canSeeReservationCalendar) {
      navigate("/dashboard/reservation/calendar");
    } else if (user.permissions?.canEditOrderOnPOS) {
      navigate("/dashboard/tables");
    } else if (user?.permissions?.canSeeOrderProcessingScreen) {
      navigate("/dashboard/orders");
    } else if (user?.permissions?.canSeeKitchenScreen) {
      navigate("/dashboard/kitchen");
    } else if (user?.permissions?.canViewStaff) {
      navigate("/dashboard/user/account");
    } else {
      navigate("/dashboard/menu/display");
    }
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post(
      "https://api-dev-minimal-v4.vercel.app/api/account/register",
      {
        email,
        password,
        firstName,
        lastName,
      }
    );
    const { accessToken, user } = response.data;

    localStorage.setItem("accessToken", accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: "LOGOUT",
    });
  }, []);

  // Reset
  const resetPassword = useCallback(
    async (old_password, new_password, user) => {
      // const response = await axios.post('https://api-dev-minimal-v4.vercel.app/api/account/login', {
      const response = await axios.patch(
        `${backendUrl}/venueUsers/changePassword/${user?.id}`,
        {
          old_password,
          new_password,
        }
      );

      dispatch({
        type: "RESET",
        payload: {
          user,
        },
      });
      return response;
    },
    []
  );

  const updateUser = useCallback((updatedUser) => {
    updatedUser.displayName = `${updatedUser?.firstName} ${updatedUser?.lastName}`;
    dispatch({
      type: "UPDATE_USER",
      payload: {
        user: updatedUser,
      },
    });
  }, []);

  const addPaymentMethod = useCallback(
    (newPaymentMethod) => {
      const updatedPaymentMethods = [...state.paymentMethods, newPaymentMethod];
      dispatch({
        type: "UPDATE_PAYMENT_METHOD",
        payload: {
          paymentMethods: updatedPaymentMethods,
        },
      });
    },
    [state.paymentMethods]
  );

  const updatePaymentMethod = useCallback((updatedPaymentMethod) => {
      dispatch({
        type: "UPDATE_PAYMENT_METHOD",
        payload: {
          paymentMethods: state.paymentMethods.map((method) =>
            method.id === updatedPaymentMethod.id
              ? updatedPaymentMethod
              : method
          ),
        },
      });
    },
    [state.paymentMethods]
  );
  const deletePaymentMethod = useCallback(
    (paymentMethodId) => {
      const updatedPaymentMethods = state.paymentMethods.filter(
        (method) => method.id !== paymentMethodId
      );
      dispatch({
        type: "UPDATE_PAYMENT_METHOD",
        payload: {
          paymentMethods: updatedPaymentMethods,
        },
      });
    },
    [state.paymentMethods]
  );
  

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      availablePaymentOptions:state.paymentMethods,
      method: "jwt",
      login,
      register,
      logout,
      resetPassword,
      updateUser,
      addPaymentMethod,
      updatePaymentMethod,
      deletePaymentMethod
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      state.paymentMethods,
      login,
      logout,
      register,
      resetPassword,
      updateUser,
      addPaymentMethod,
      updatePaymentMethod,
      deletePaymentMethod
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}