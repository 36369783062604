import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const initialState = {
  isLoading: false,
  error: null,
  adjustments: [],
  adjustment: null,
};

const slice = createSlice({
  name: "adjustment",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getAdjustmentsSuccess(state, action) {
      state.isLoading = false;
      state.adjustments = action.payload;
    },
    getadjustmentSuccess(state, action) {
      state.isLoading = false;
      state.adjustment = action.payload;
    },
    resetStockStore() {
      return initialState;
    },
  },
});

export default slice.reducer;

export function getAdjustments(venueId, levelId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/inventoryAdjustment`, {
        params: {
          venueId,
          levelId,
          isDeleted: false,
          isActiveAdjustment: false,
          limit: 100000000
        },
      });

      dispatch(slice.actions.getAdjustmentsSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAdjustment(adjustmentId, venueUserId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`${backendUrl}/inventoryAdjustment/openAdjustmentToEdit/${adjustmentId}?venueUserId=${venueUserId}`);
      dispatch(slice.actions.getadjustmentSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getActiveInventoryAdjustment(venueId, levelId, venueUserId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/inventoryAdjustment/activeInventoryAdjustment`, {
        params: {
          venueId,
          levelId,
          venueUserId: venueUserId,
        },
      });
      dispatch(slice.actions.getadjustmentSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw error
    }
  };
}



export function addIngredientInAdjustment(data,ingredientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `${backendUrl}/ingredient/addIngredientInAdjustment/${ingredientId}`,
        data,
      );
      dispatch(slice.actions.getadjustmentSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw error
    }
  };
}



export function deleteIngredientFromAdjustment(data,ingredientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `${backendUrl}/ingredient/deleteIngredientFromAdjustment/${ingredientId}`,
        data,
      );
      dispatch(slice.actions.getadjustmentSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeDraftedIngredientsFromAdjustment(deletedId,deletedById) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${backendUrl}/inventoryAdjustment/${deletedId}`, {
        params: {
          deletedById,
          deletedBy:"venueUser",
        },
      });
      dispatch(slice.actions.getAdjustmentsSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function clearAdjustmentAfterUpdating() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetStockStore());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
