import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPayLoading: false,
  error: null,
  orderCartId: null,
  responseTime: null
};

const slice = createSlice({
  name: "orderInProcess",
  initialState,
  reducers: {
    setOrderSuccess(state, action) {
      state.isPayLoading = action.payload?.isLoading;
      state.orderCartId = action.payload?.cartId;
      state.responseTime = action.payload.startTime;
    },
    resetOrderState() {
      return initialState;
    },
  },
});

export default slice.reducer;

export function orderPlaceManagement(cartId,isLoading) {
  return async (dispatch) => {
    const startTime = Date.now()
    try {
      const payload = {
        cartId,
        isLoading,
        startTime
      };
      dispatch(slice.actions.setOrderSuccess(payload));
    } catch (error) {
      dispatch(slice.actions.resetOrderState());
      throw error;
    }
  };
}

export function resetOrderState() {
  return (dispatch) => {
    dispatch(slice.actions.resetOrderState());
  };
}
