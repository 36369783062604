import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const initialState = {
  isLoading: false,
  error: null,
  kitchenItems: [],
  selectedItem: null,
};

const kitchenSlice = createSlice({
  name: "kitchen",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getKitchenItemsSuccess(state, action) {
      state.isLoading = false;
      state.kitchenItems = action.payload;
    },
  },
});

// Reducer
export default kitchenSlice.reducer;

export function getKitchenItems(data) {
  return async (dispatch) => {
    dispatch(kitchenSlice.actions.startLoading());
    try {
      const response = await axios.post(
        `${backendUrl}/cart/ordersForPOS`,
        data
      );
      dispatch(
        kitchenSlice.actions.getKitchenItemsSuccess(response.data.results)
      );
    } catch (error) {
      dispatch(kitchenSlice.actions.hasError(error));
    }
  };
}

export function updateKitchenItemAvailability(orderId, items, token) {
  return async (dispatch, getState) => {
    const currentState = getState().kitchenSlice;
    try {
      const response = await axios.post(
        `${backendUrl}/posCart/updateItemInOrder`,
        {
          orderId: orderId,
          items: items,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error(error);
      dispatch(kitchenSlice.actions.hasError(error));
      throw error;
    }
  };
}

export function finalizeKitchenOrder(data) {
  return async (dispatch, getState) => {
    // dispatch(kitchenSlice.actions.startLoading());
    const currentState = getState().kitchenSlice;
    try {
      await axios.post(`${backendUrl}/cart/changeOrderState`, data);
      const { orderId } = data;
      const updatedKitchenItems = currentState.kitchenItems.filter(
        (item) => item.id !== orderId
      );

      dispatch(
        kitchenSlice.actions.getKitchenItemsSuccess(updatedKitchenItems)
      );
    } catch (error) {
      dispatch(kitchenSlice.actions.hasError(error));
    }
  };
}
