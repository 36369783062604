import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const initialState = {
  isLoading: false,
  error: null,
  posCarts: [],
  posCart: null,
};

const slice = createSlice({
  name: "posCart",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCartsSuccess(state, action) {
      state.isLoading = false;
      state.posCarts = JSON.parse(JSON.stringify(action.payload));
    },

    reRenderCartsSuccess(state, action) {
      state.posCart.map((cg) => {
        if (cg.id === action.payload) {
          cg.selected = true;
        }
      });
    },

    getCartSuccess(state, action) {
      state.isLoading = false;
      state.posCart = JSON.parse(JSON.stringify(action.payload));
    },

    updatePosCart(state, action) {
      state.isLoading = false;
      state.posCart = {
        ...state.posCart,
        customerObject: action.payload.customer,
        cartType: action.payload.cartType,
        orderType: action.payload.cartType,
        deliveryFees: action.payload.deliveryFee,
        isDeliveryFeesApplicable: action.payload.includeDeliveryFee,
        venueDeliveryCustomerId: action.payload.venueDeliveryCustomerId,
        rider: action.payload.selectedRider,
        isOrderEdit: action.payload.tempOrderEdit,
      };
    },
    updateReceiptCountOnCart(state, action) {
      state.isLoading = false;
      state.posCart = {
        ...state.posCart,
        receiptCount: action.payload.receiptCount,
      };
    },
    updateCartAfterDiscount(state, action) {
      state.isLoading = false;
      state.posCart = JSON.parse(JSON.stringify(action.payload));
    },
    clearState(state) {
      state.isLoading = false;
      state.error = null;
      state.posCarts = [];
      state.posCart = null;
    },
  },
});

export default slice.reducer;

export const { clearState, updatePosCart } = slice.actions;

export function getCarts(levelId, venueId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/posCart?levelId=${levelId}&venueId=${venueId}`
      );
      dispatch(slice.actions.getCartsSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reRenderCarts(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.reRenderCartsSuccess(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCart(levelId, venueId, venueUserId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/posCart?levelId=${levelId}&venueId=${venueId}&venueUserId=${venueUserId}`
      );
      dispatch(slice.actions.getCartSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function removeItemFromCart(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${backendUrl}/posCart/removeItem`,
        data
      );
      dispatch(slice.actions.getCartSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItemToCart(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${backendUrl}/posCart/addItem`,
        data
      );
      dispatch(slice.actions.getCartSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteCartById(cartId, token, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${backendUrl}/posCart/deleteCartById/${cartId}?deletedById=${id}&deletedBy=${"venueUser"}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(slice.actions.clearState());
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updatePosCartWithCustomer(
  customer,
  cartType,
  deliveryFee,
  includeDeliveryFee,
  venueDeliveryCustomerId,
  selectedRider,
  tempOrderEdit
) {
  return (dispatch) => {
    dispatch(
      slice.actions.updatePosCart({
        customer,
        cartType,
        deliveryFee,
        includeDeliveryFee,
        venueDeliveryCustomerId,
        selectedRider,
        tempOrderEdit,
      })
    );
  };
}



// receipt count
export function updateReceiptCount(cartId, receiptName) {
  return async (dispatch, getState) => {
    const { posCart } = getState().posCart;
    if (!posCart || !posCart.id) {
      console.error("posCart does not exist or is invalid.");
      return;
    }
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `${backendUrl}/posCart/updateReceiptCount?cartId=${cartId}&receiptName=${receiptName}`,
      );
      dispatch(slice.actions.updateReceiptCountOnCart(response?.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error("error", error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateCartAfterDiscount(res) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateCartAfterDiscount(res));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

