import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  stocks: [],
  liveStocks: [],
  stock: null,
  updateAtLogs: "",
};

const slice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET STOCKS
    getStocksSuccess(state, action) {
      state.isLoading = false;
      state.stocks = action.payload;
    },

    // GET STOCK
    getStockSuccess(state, action) {
      state.isLoading = false;
      state.stock = action.payload;
    },
    // GET LiveSTOCK
    getLiveStockSuccess(state, action) {
      state.isLoading = false;
      state.liveStocks = action.payload;
    },
    getLogsTimeSuccess(state, action) {
      state.isLoading = false;
      state.updateAtLogs = action.payload;
    },
    resetStockStore() {
      return initialState;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// All Stocks

export function getStocks(venueId, levelId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/stock/`, {
        params: {
          venueId,
          levelId,
          isDeleted: false,
          isActiveStock: false,
          limit: 9999999999999,
        },
      });

      dispatch(slice.actions.getStocksSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStock(stockId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/stock/${stockId}`);
      dispatch(slice.actions.getStockSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getActiveStock(stockId, venueUserId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `${backendUrl}/stock/openStockToEdit/${stockId}?venueUserId=${venueUserId}`
      );
      dispatch(slice.actions.getStockSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeDraftedIngredientsFromStock(deletedId, deletedById) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${backendUrl}/stock/${deletedId}`, {
        params: {
          deletedById,
          deletedBy: "venueUser",
        },
      });
      dispatch(slice.actions.getStockSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// Live Stock

export function getLiveInventoryStock(venueId, levelId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/ingredient/WithSumOfQuantity`,
        {
          params: {
            venueId,
            levelId,
          },
        }
      );
      dispatch(slice.actions.getLiveStockSuccess(response?.data?.results));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLastCronLog() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/cronJob/getLastCronLog`);
      const logDate = new Date(response?.data);

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      const formattedDate = logDate.toLocaleString("en-US", options);
      dispatch(slice.actions.getLogsTimeSuccess(formattedDate));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// Stock Ingredeints

export function addIngredientInStock(data, params, ingredientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `${backendUrl}/ingredient/addIngredientInStock/${ingredientId}`,
        data,
        { params }
      );

      dispatch(slice.actions.getStockSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getActiveInventoryStock(venueId, levelId, venueUserId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/stock/activeStock`, {
        params: {
          venueId,
          levelId,
          venueUserId: venueUserId,
        },
      });
      dispatch(slice.actions.getStockSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteIngredientFromStock(data, ingredientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `${backendUrl}/ingredient/deleteIngredientFromStock/${ingredientId}`,
        data
      );
      dispatch(slice.actions.getStockSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearStoreAfterUpdating() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetStockStore());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
